.connectdots > .swiper-wrapper {
    transition-timing-function: linear;
}

.reverseconnectdots > .swiper-wrapper {
    transition-timing-function: linear;
}

.group-selection-box {
    background: linear-gradient(to bottom, #370377, #5B3092);
    /* For older browsers that do not support gradients */
    background: -webkit-linear-gradient(top, #370377, #5B3092);
    background: -moz-linear-gradient(top, #370377, #5B3092);
    background: -o-linear-gradient(top, #370377, #5B3092);
}

.session-selection-box {
    background: linear-gradient(to bottom, #07C897, #01B083);
    /* For older browsers that do not support gradients */
    background: -webkit-linear-gradient(top, #07C897, #01B083);
    background: -moz-linear-gradient(top, #07C897, #01B083);
    background: -o-linear-gradient(top, #07C897, #01B083);
    border: 1px solid #E7E5E9;
}


.testimonial-pagination > .swiper-pagination-bullet-active {
    background-color: #947ec7 !important;
}

.testimonial-pagination > .swiper-pagination-bullet {
    height: 10px !important;
    width: 10px !important;
    margin: 0 8px !important;
}

.testimonial-pagination-hidden {
    display: none !important;
}

.hero-pagination-hidden {
    display: none !important;
}

.swiper-button-next::after, .swiper-button-prev::after {
    content: '' !important;
}

.swiper-pagination-fraction {
    width: auto !important;
    bottom: 0 !important;
    align-self: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #D3D2D5;
}

.swiper-pagination-current {
    color: black;
}

.swiper-pagination {
    position: relative !important;
    min-width: 50px;
    text-align: right;
}

.solution-story-pagination .swiper-pagination-current {
    color: white !important;
}

.solution-story-pagination.swiper-pagination-fraction {
    color: #8d6bc0 !important;
}

.solution-story .swiper-pagination-bullet {
    background-color: #ede5ff !important;
}

.hero-pagination > .swiper-pagination-bullet {
    background-color: #947ec7 !important;
    opacity: 1 !important;
    height: 1.75rem !important;
    width: calc(16.6% - 0.5rem) !important;
    margin: 0 0.25rem !important;
    border-radius: 2.5rem !important;
}

.hero-pagination > .swiper-pagination-fraction {
    display: none !important;
}

.hero-pagination > .swiper-pagination-bullet-active {
    background-color: #3a3f74 !important;
}

@keyframes bounce {
    0%, 100%, 20%, 50%, 80% {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0)
    }
    40% {
        -webkit-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
        transform: translateY(-10px)
    }
    60% {
        -webkit-transform: translateY(-5px);
        -ms-transform: translateY(-5px);
        transform: translateY(-5px);
    }
}

.accordion.MuiAccordion-root::before {
    display: none;
}

.accordion.expanded.MuiAccordion-root::before {
    display: none;
}

.stellar-container {
    /*animation: reveal-stellar 1s ease-out;*/
}

@keyframes reveal-stellar {
    0% {
        top: -32rem;
    }
    20% {
        top: -32rem;
    }
    100% {
        top: 0;
    }
}

.stellar-right-eye {
    width: 2.5rem;
    height: 2.5rem;
    background: #f7f8f9;
    position: relative;
    z-index: 1;
    top: 293px;
    left: 326px;
    border-radius: 100rem;
    transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
}

.stellar-right-eye::after {
    content: " ";
    bottom: 13px;
    right: 9px;
    position: absolute;
    width: 1.46rem;
    height: 1.46rem;
    background: #000;
    border-radius: 15px;
    transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
}

.stellar-right-eye-small {
    width: 1.42rem;
    height: 1.42rem;
    top: 173px;
    left: 178px;
    transition: all 0.5s ease-in-out;
}

.stellar-right-eye-small::after {
    content: " ";
    bottom: 8px;
    right: 7px;
    position: absolute;
    width: 0.79rem;
    height: 0.79rem;
    background: #000;
    border-radius: 15px;
    transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
}

.stellar-left-eye {
    width: 2.5rem;
    height: 2.5rem;
    background: #f7f8f9;
    position: relative;
    z-index: 1;
    top: 211px;
    left: 215px;
    border-radius: 100rem;
    transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
}

.stellar-left-eye::after {
    content: " ";
    bottom: 13px;
    right: 9px;
    position: absolute;
    width: 1.46rem;
    height: 1.46rem;
    background: #000;
    border-radius: 15px;
    transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
}

.stellar-left-eye-small {
    width: 1.42rem;
    height: 1.42rem;
    top: 127px;
    left: 118px;
    transition: all 0.5s ease-in-out;
}

.stellar-left-eye-small::after {
    content: " ";
    bottom: 8px;
    right: 7px;
    position: absolute;
    width: 0.79rem;
    height: 0.79rem;
    background: #000;
    border-radius: 15px;
    transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
}

.large-pupil {
    width: 3rem;
    height: 3rem;
    transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
}

.large-pupil::after {
    width: 1.9rem;
    height: 1.9rem;
    bottom: 15px;
    right: 10px;
    transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
}

.stellar-man-hover {
    animation: move-stellar 5s ease-out infinite;
    position: relative;
}

.stellar-man-hover-fast {
    animation: move-stellar 3s ease-out infinite;
    position: relative;
}

.stellar-left-hand {
    position: absolute;
    top: 299px;
    left: -4px;
    transform-origin: 97px 41px;
    z-index: 1;
}


.stellar-left-hand-small {
    top: 88px;
    left: 15px;
    transform-origin: unset;
}

.stellar-left-hand-wave {
    animation: stellar-left-hand 5s ease-out infinite;
}

.stellar-left-hand-wave-small {
    animation: stellar-left-hand-small 2s ease-out infinite;
}

.rotate-eye-section-5 {
    animation: rotate-eye-section-5 8s ease-out infinite;
}

@keyframes rotate-eye-section-5 {
    0%, 40% {
        transform: rotate(-110deg)
    }
    60%, 80% {
        transform: rotate(-50deg)
    }
    100% {
        transform: rotate(-110deg)
    }
}

.rotate-eye-section-7 {
    transform: rotate(260deg);
    animation: rotate-eye-section-7 8s infinite;
}

@keyframes rotate-eye-section-7 {
    0% {
        transform: rotate(260deg)
    }
    30% {
        transform: rotate(290deg)
    }
    90% {
        transform: rotate(230deg)
    }
}

.rotate-eye-section-7::after {
    animation: eye-straight-and-down 2s;
}

.rotate-eye-section-7-bottom::after {
    animation: eye-straight-and-down 2s;
}

.rotate-eye-section-9-bottom {
    transform: rotate(-30deg);
}

.rotate-eye-section-9-top {
    transform: rotate(266deg);
    animation: rotate-eye-section-9 6s infinite;
}

@keyframes rotate-eye-section-9 {
    0% {
        transform: rotate(200deg)
    }
    30% {
        transform: rotate(230deg)
    }
    70% {
        transform: rotate(250deg)
    }
    100% {
        transform: rotate(200deg)
    }

}

.stellar-forgotten-1 {
    animation: stellar-forgotten-1 4s infinite;
}

@keyframes stellar-forgotten-1 {
    50% {
        top: -40px;
    }
}

@keyframes eye-straight-and-down {
    0%, 80% {
        bottom: 5px;
        right: 5px;
    }

    100% {
        bottom: 8px;
        right: 7px;
    }
}

.stellar-right-hand {
    z-index: 1;
    position: absolute;
    top: 223px;
    right: -4px;
    transform-origin: 8px 63px;
}


.stellar-right-hand-wave {
    animation: stellar-right-hand 5s ease-out infinite;
}

.stellar-right-hand-small {
    top: 246px;
    left: 239px;
    transform-origin: unset;
}

@keyframes move-stellar {
    0% {
        top: 0
    }
    50% {
        top: 2rem
    }
    100% {
        top: 0
    }
}

@keyframes stellar-right-hand {
    0% {
        transform: rotate(10deg)
    }
    50% {
        transform: rotate(-25deg) translateY(-30px) translateX(10px)
    }
    100% {
        transform: rotate(10deg)
    }
}

@keyframes stellar-left-hand {
    0% {
        transform: rotate(-10deg)
    }
    50% {
        transform: rotate(25deg) translateY(-30px) translateX(-10px)
    }
    100% {
        transform: rotate(-10deg)
    }
}

@keyframes stellar-left-hand-small {
    0% {
        transform: rotate(-15deg)
    }
    50% {
        transform: rotate(15deg)
    }
    100% {
        transform: rotate(-15deg)
    }
}


.stellar-triangle-bg {
    height: calc(100% - 5rem);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.stellar-fade-in-right {
    animation: stellar-fade-in-right 1s ease-out;
}

@keyframes stellar-fade-in-right {
    0% {
        opacity: 0;
        transform: translateX(100px)
    }
    100% {
        opacity: 1;
        transform: translateX(0)
    }
}

.stellar-hidden {
    opacity: 0;
}

.section-7-fade-in-bottom {
    animation: fade-in 1s ease-out;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.section-9-move {
    /*animation: move-section-9 1s ease-in-out;*/
    right: 175px !important;
    top: 591px !important;
    scale: 1.8;
}

.rotate-left-hand-section-9-bottom {
    top: 228px;
    transform: rotate(-80deg);
    transition: all 1s ease-in-out;
    animation: rotate-left-hand-section-9-bottom 1s ease-in-out;
}

.rotate-right-hand-section-9-bottom {
    top: 71px;
    transform: rotate(-72deg);
    transition: all 1s ease-in-out;
    animation: rotate-right-hand-section-9-bottom 1s ease-in-out;
}

@keyframes rotate-left-hand-section-9-bottom {
    0% {
        left: 15px;
    }
    50% {
        left: -20px;
    }
    100% {
        left: 15px;
    }
}

@keyframes rotate-right-hand-section-9-bottom {
    0% {
        left: 239px;
    }
    50% {
        left: 274px;
    }
    100% {
        left: 239px;
    }
}

.angle-stellar-man {
    animation: angle-stellar-man 4s ease-in-out infinite;
}

@keyframes angle-stellar-man {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(10px);
    }
}

.why-stellar-man {
    animation: why-stellar-man 4s ease-in-out infinite;
}

@keyframes why-stellar-man {
    0%, 100% {
        transform: translateY(5px);
    }
    50% {
        transform: translateY(-5px);
    }
}

.simple-highlight {
    background-color: #ffffd7;
    padding: 0.05em 0.2em;
}