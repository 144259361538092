body {
    margin: 0;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #434343;
    font-weight: 400;
    font-family: 'Baloo 2', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:link {
    text-decoration: none;
    color: #947EC7;
}

a:visited {
    color: #947EC7;
    text-decoration: none;
}

a:hover {
    color: #947EC7;
    text-decoration: none;
}

a:active {
    color: #947EC7;
    text-decoration: none;
}

